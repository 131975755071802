import React, { useEffect, useState, useRef } from 'react';
import { Card, Col, Row, Spinner, Table } from 'react-bootstrap';
import SortableTH from '../Components/SortableTH';
import { Link } from 'react-router-dom';
import { faMagnifyingGlass } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { TopLocationsGraph } from '../Components/TopLocationsGraph';
import TopColdestLocationsGraph from '../Components/TopColdestLocationsGraph';
import SEO from '../Components/Seo';
import { useWindowDimension } from '../Hooks/useWindowDimension';
import TopWindiestLocationsGraph from '../Components/TopWindiestLocationsGraph';
import TopSnowiestLocationsGraph from '../Components/TopSnowiestLocationsGraph';
import {determineCurrentObservationIcon, determmineTemperatureColor} from '../Helper'

function LiveScores(){

    const [rowsContent, setRowsContent] = useState(<tr><td colSpan="13">Loading data. Please wait...</td></tr>);
    const [lastUpdated, setLastUpdated] = useState(new Date());
    const [liveScoresData, setLiveScoresData] = useState([]);
    const [rawScoresData, setRawScoresData] = useState([]);
    const [currentSort, setCurrentSort] = useState({column: "score", ascending: false});
    const [pageLoaded, setPageLoaded] = useState(false);
    const checkboxRef = useRef(null)
    const [width] = useWindowDimension();

    useEffect(() => {
        fetchData("score", false);
    }, []);

    useEffect(() => {        
        const interval = setInterval(() => { 
            fetchData(currentSort.column, currentSort.ascending);
        }, 300000); 
        return () => clearInterval(interval);
    }, [currentSort]);

    function fetchData(sortColumn, sortAscending) {

        if(sortColumn == null){
            setCurrentSort((sort) => sort);
        }
        
        setPageLoaded(false);

        fetch("scores/live/locations")
            .then((response) => {
                return response.json();
            })
            .then((data) => {
                setRawScoresData(data);
                sort(data, sortColumn, sortAscending);
                setLastUpdated(new Date());
            })
            .catch(() => {

            })
            .finally(() => {
                setPageLoaded(true);
            });
    }

    function sort(data, field, sortAcending){

        const sortedData = data.sort(function(a, b){
            
            let fieldA = a.score; 
            let fieldB = b.score; 

            if(field === "name"){
                fieldA = a.locationName.toUpperCase(); 
                fieldB = b.locationName.toUpperCase(); 
            }
            else if(field === "weather"){
                fieldA = a.observation.toUpperCase(); 
                fieldB = b.observation.toUpperCase(); 
            }
            else if(field === "wind"){
                fieldA = a.wind; 
                fieldB = b.wind; 
            }
            else if(field === "temp"){
                fieldA = a.currentTemperature; 
                fieldB = b.currentTemperature; 
            }
            else if(field === "visibility"){
                fieldA = a.currentVisibility; 
                fieldB = b.currentVisibility; 
            }
            else if(field === "precip"){
                fieldA = a.precipitation; 
                fieldB = b.precipitation; 
            }
            else if(field === "snow"){
                fieldA = a.snow; 
                fieldB = b.snow; 
            }
            else if(field === "minTemp"){
                fieldA = a.minTemp; 
                fieldB = b.minTemp; 
            }
            else if(field === "maxTemp"){
                fieldA = a.maxTemp; 
                fieldB = b.maxTemp; 
            }
            else if(field === "hdd"){
                fieldA = a.hdd; 
                fieldB = b.hdd; 
            }

            if(sortAcending){
                if (fieldA < fieldB) {
                    return -1;
                }
                if (fieldA > fieldB) {
                    return 1;
                }
            }
            else{            
                if (fieldA > fieldB) {
                    return -1;
                }
                if (fieldA < fieldB) {
                    return 1;
                }
            }

            // names must be equal
            return 0;
        })

        loadData(sortedData, field);
        setLiveScoresData(sortedData);        
    }

    function loadData(data, sortedColumn) {

        let rows = [];

        if(checkboxRef.current.checked){
            data = data.filter(x => x.hasPicksToday);
        }

        if (data.length > 0) {
            rows = data.map((x, index) =>
            (
                <tr key={index}>
                    <td className={sortedColumn === "name" ? "text-center align-middle sorted-column" : "text-center align-middle"}>
                        <Link className="table-link" to={"/location/" + x.locationIdentifier + "/" + x.locationName} title={x.locationName}>{buildLocationNameCell(x)}</Link>                        
                    </td>
                    <td className={sortedColumn === "weather" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{buildWeatherCell(x)}</td>                    
                    <td className={sortedColumn === "temp" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{buildTemperatureCell(x.currentTemperature)}</td>
                    <td className={sortedColumn === "visibility" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.currentVisibility} mi</td>
                    <td className={sortedColumn === "precip" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.precipitation.toFixed(2)}&quot;</td>
                    <td className={sortedColumn === "snow" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.snow.toFixed(1)}&quot;</td>
                    <td className={sortedColumn === "minTemp" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.minTemp}°F</td>
                    <td className={sortedColumn === "maxTemp" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.maxTemp}°F</td>
                    <td className={sortedColumn === "hdd" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.hdd}</td>
                    <td className={sortedColumn === "wind" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.wind}mph</td>
                    <td className={sortedColumn === "score" ? "text-center align-middle sorted-column" : "text-center align-middle"}>{x.score}</td>
                    <td className="text-center align-middle"><a className="table-link" href={`http://weather.rap.ucar.edu/surface/index.php?metarIds=k${x.locationIdentifier}&hoursStr=past+24+hours`} target="_blank" rel="noreferrer"><FontAwesomeIcon icon={faMagnifyingGlass}/></a></td>
                </tr>
            ));
        }
        else {
            if(checkboxRef.current.checked){
                rows = <tr><td colSpan="13">No locations were picked today.</td></tr>;
            }
            else{
                rows = <tr><td colSpan="13">Live scores are currently not available.</td></tr>;
            }
        }
        
        setRowsContent(rows);
    }

    function sortHandler(field){

        let sortAcending = true;

        if(field === currentSort.column){
            sortAcending = !currentSort.ascending;
        }

        sort(liveScoresData, field, sortAcending);
        setCurrentSort({column: field, ascending: sortAcending});
    }
 
    function checkboxChangeHandler(){
        loadData(liveScoresData, currentSort.column);
    }

    function buildLocationNameCell(pick){

        let limit = 100;

        if(width < 1000)
        {
            limit = 10;
        }        

        return (
            <>
                {pick.locationName.length <= limit ? pick.locationName : pick.locationName.substring(0, limit) + "..."}
            </>
        )
    }

    function buildTemperatureCell(currentTemperature){
        const styles = {
            color: determmineTemperatureColor(currentTemperature)
         }

         return <span style={styles}>{currentTemperature}°F</span>
    }

    function determineGraphCount(){

        let limit = 25;

        if(width < 500)
        {
            limit = 10;
        } 
        else if(width < 1000)
        {
            limit = 15;
        } 

        return limit;
    }

    function buildWeatherCell(location){
        
        let currentWeatherIcon = determineCurrentObservationIcon(location.currentWeatherIcon, location.observation);
        let currentWeatherText = [];

        if(width >= 800){
            currentWeatherText =  [<span key={1}>{location.observation.trim().length > 0 ? location.observation : "Not available" }</span>, currentWeatherIcon];
        }
        else{
            currentWeatherText = currentWeatherIcon;
        }
 
        return currentWeatherText;
    }

    return (
        <>
        <SEO 
            title="FantasyBlizzard.com -- Live Scores" 
            description="Live location scores with snow, wind, cold, current weather, and more for Fantasy Blizzard" 
            crawl={true} 
        />
            <Row>
                <Col sm="12">
                    <h1>Live Scores</h1>
                    <p>Below are auto generated estimated scores of all of the locations that are available in our data feed. Wind and temperature are taken from METAR reports reports so those are fairly true. Snow, on the other hand, is estimated using a homegrown algorithm that takes into consideration a number of atmosphere conditions and METAR observations. Snow estimates are generally in the ball park, but can be significantly off occasionally.</p>
                    <p>This page will automatically refresh every few minutes. The scoring engine runs every 10 minutes during the season. To see only locations that have picks for today, check the &quot;Show only for today&quot; checkbox below.</p>
                    
                </Col>
            </Row>
            <Row className="mb-1">
                <Col className='text-right'>
                    <strong>Last Updated: </strong> {lastUpdated.toLocaleString().replace(",", "")} ET
                </Col>
            </Row>
            <Row className="mb-3">
                <Col col="12">
                    <TopLocationsGraph data={rawScoresData} count={determineGraphCount()} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col col="12">
                    <TopColdestLocationsGraph data={rawScoresData} count={determineGraphCount()} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col col="12">
                    <TopSnowiestLocationsGraph data={rawScoresData} count={determineGraphCount()} />
                </Col>
            </Row>
            <Row className="mb-3">
                <Col col="12">
                    <TopWindiestLocationsGraph data={rawScoresData} count={determineGraphCount()} />
                </Col>
            </Row>
            <Row>
                <Col>
                    <Card>
                        <Card.Header className="bg-dark text-white">All Locations Raw Data</Card.Header>
                        <Card.Body>
                            <Row>
                                <Col className="text-right"  sm="12">
                                    <strong>Show only locations with picks: </strong> <input type="checkbox" onChange={checkboxChangeHandler}  ref={checkboxRef} />                                    
                                </Col>
                            </Row>
                            {!pageLoaded && <Row><Col className='text-center'><Spinner className='login-loading' animation="border" /></Col></Row>}
                            {pageLoaded && <Row className='mb-3'>
                                <Col sm="12">
                                    <Table striped bordered hover size="sm" responsive>
                                        <thead className="table-active">
                                            <tr>
                                                <SortableTH field="name" text="Name" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="weather" text="Observation" currentSort={currentSort} sortData={sortHandler} />                                                
                                                <SortableTH field="temp" text="Temp" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="visibility" text="Visibility" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="precip" text="Precip" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="snow" text="Snow" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="minTemp" text="Min" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="maxTemp" text="Max" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="hdd" text="HDD" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="wind" text="Gust" currentSort={currentSort} sortData={sortHandler} />
                                                <SortableTH field="score" text="Score" currentSort={currentSort} sortData={sortHandler} /> 
                                                <th className="text-center align-middle">METARS</th>                     
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {rowsContent}
                                        </tbody>
                                    </Table>  
                                </Col>
                            </Row> }
                        </Card.Body>
                    </Card>                
                </Col>
            </Row>
        </>
    )
}

export default LiveScores;